<template>
	<div>
		<div>
			<img class="cooperation_pic display-block" :src="info.join_us_banner" alt="" />
		</div>
		<div class="mart10 bg-white">
			<div class="h54 justify-content-center-between paddlr20 cure-pointer"
				@click="applyPopup = true, applyType = 1">
				<div class="align-items-center ">
					<img class="coo_01 display-block" src="@/assets/img/coo_01.png" alt="" />
					<span class="fz16 co-333333 marl12 l16">个人入驻</span>
				</div>
				<div class="align-items-center">
					<span class="fz16 co-010085 l16">立即申请</span>
					<img class="coo_02 display-block marl12" src="@/assets/img/coo_02.png" alt="" />
				</div>
			</div>
			<div class="h54 justify-content-center-between paddlr20 cure-pointer"
				@click="applyPopup = true, applyType = 2">
				<div class="align-items-center">
					<img class="coo_01 display-block" src="@/assets/img/coo_03.png" alt="" />
					<span class="fz16 co-333333 marl12 l16">企业入驻</span>
				</div>
				<div class="align-items-center">
					<span class="fz16 co-010085 l16">立即申请</span>
					<img class="coo_02 display-block marl12" src="@/assets/img/coo_02.png" alt="" />
				</div>
			</div>
		</div>
		<div class="mart10 bg-white">
			<div class="h54 justify-content-center-between paddlr20 fz16 co-333333">联系我们</div>
			<div class="justify-content-center-between paddlr20 fz16 co-333333">
				<div>
					<div class="fz14 co-333333">联系电话：{{info.join_us_phone ||''}}</div>
					<div class="fz14 co-333333 mart10">联系微信：{{info.join_us_wechat ||''}}</div>
				</div>
				<div @click="bigImgShow(info.join_us_qrcode)">
					<img class="code display-block" :src="info.join_us_qrcode" alt="" />
				</div>
			</div>
			<div class="paddb20 w100"></div>
		</div>

		<!-- 入驻 -->
		<el-dialog custom-class="boderra8" v-model="applyPopup" width="600px" top="0" center>
			<div class="fz20 co-333333 popup-title center paddt20 paddb30">申请个人入驻</div>
			<div class="paddlr20" v-if="applyType == 1">
				<div class="center w100">
					<div class="align-items-center popup-name">
						<span class="fz16 co-FE3000">*</span>
						<span class="fz16 co-333333 marl4 co-name-title">姓名</span>
					</div>
					<div class="input-popup-area display-flex marl15"> 
						<input class="fz16 co-333333 flex-1 h100 input-popup" v-model="name" placeholder="请输入姓名" />
					</div>
				</div>
				<div class="center w100 mart20">
					<div class="align-items-center popup-name">
						<span class="fz16 co-FE3000">*</span>
						<span class="fz16 co-333333 marl4">本科毕业院校校</span>
					</div>
					<div class="input-popup-area display-flex marl15">
						<input class="fz16 co-333333 flex-1 h100 input-popup" v-model="schoolName"
							placeholder="请输入毕业院校" />
					</div>
				</div>
				<div class="center w100 mart20">
					<div class="align-items-center popup-name">
						<span class="fz16 co-FE3000">*</span>
						<span class="fz16 co-333333 marl4">海外院校</span>
					</div>
					<div class="input-popup-area display-flex marl15">
						<input class="fz16 co-333333 flex-1 h100 input-popup" v-model="overseasName"
							placeholder="请输入海外院校" />
					</div>
				</div>
				<div class="align-items-center w100 mart20 " style="padding-left: 23px;">
					<div class="align-items-center popup-name">
						<span class="fz16 co-FE3000">*</span>
						<span class="fz16 co-333333 marl4">是否有教学经验</span>
					</div>
					<div class="display-flex marl15">
						<div class="align-items-center" @click="isHave = 1">
							<img class="display-block is-have"
								:src="require(`@/assets/img/${isHave == 1 ? 'is_have' : 'no_choose'}.png`)" alt="" />
							<span class="display-block marl12 fz16 co-333333">是</span>
						</div>
						<div class="align-items-center" @click="isHave = 0">
							<img class="display-block is-have marl50"
								:src="require(`@/assets/img/${isHave == 1 ? 'no_choose' : 'is_have'}.png`)" alt="" />
							<span class="display-block marl12 fz16 co-333333">否</span>
						</div>
					</div>
				</div>

				<div class="center w100 mart20">
					<div class="align-items-center popup-name">
						<span class="fz16 co-FE3000">*</span>
						<span class="fz16 co-333333 marl4">上传作品集</span>
					</div>
					<div class="input-popup-area display-flex marl15">
						<input class="fz16 co-333333 flex-1 h100 input-popup" v-model="workLink"
							placeholder="请输入作品集链接" />
					</div>
				</div>
			</div>

			<div v-else>
				<div class="center w100">
					<div class="align-items-center popup-name">
						<span class="fz16 co-FE3000">*</span>
						<span class="fz16 co-333333 marl4 co-name-title">机构名称</span>
					</div>
					<div class="input-popup-area display-flex marl15">
						<input class="fz16 co-333333 flex-1 h100 input-popup" v-model="mechanismname"
							placeholder="请输入机构名称" />
					</div>
				</div>
				<div class="display-flex w100 mart20 paddl30" style="padding-left: 43px;">
					<div class="align-items-center popup-name">
						<span class="fz16 co-FE3000">*</span>
						<span class="fz16 co-333333 marl4">创办时间</span>
					</div>
					<div class=" display-flex marl15">
						<el-date-picker v-model="mechanismtime" @change='datePickerChange' type="date"
							placeholder="请选择创办时间">
						</el-date-picker>
					</div>
				</div>
				<div class="center w100 mart20">
					<div class="align-items-center popup-name">
						<span class="fz16 co-FE3000">*</span>
						<span class="fz16 co-333333 marl4">机构类型</span>
					</div>
					<div class="input-popup-area display-flex marl15" @click="industry=true">
						<input class="fz16 co-333333 flex-1 h100 input-popup" disabled v-model="mechanismtypee"
							placeholder="请选择机构类型" />
					</div>
				</div>
			</div>

			<div class="center w100 mart20">
				<div class="align-items-center popup-name">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl4">联系电话</span>
				</div>
				<div class="input-popup-area display-flex marl15">
					<input class="fz16 co-333333 flex-1 h100 input-popup" maxlength="11" v-model="phone"
						placeholder="请输入联系电话" />
				</div>
			</div>
			<div class="center w100 mart20">
				<div class="display-flex">
					<div class="popup-name">
						<span class="fz16 co-FE3000">*</span>
						<span class="fz16 co-333333 marl4">微信号</span>
					</div>
					<div class="marl15">
						<div class="input-popup-area ">
							<input class="fz16 co-333333 flex-1 h100 input-popup" v-model="wechat"
								placeholder="请输入微信号" />
						</div>
						<div class="mart24 align-items-center paddb20">
							<div class="btn-popup-01 center cure-pointer fz14 co-010085" @click="save">保存</div>
							<div class="btn-popup-02 center cure-pointer fz14 co-333333 marl20" @click="cancel">取消</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
	<div class="img-show-mask" id="img-show-mask" v-show="maskBtn" @mousewheel.prevent @click="closeBigImg">

		<div>
			<img :src="bigImgSrc" class="bigImg" id="bigImg" />
		</div>
	</div>
	<el-dialog title="类型选择" :append-to-body='false' :lock-scroll='false' v-model="industry"  center
		width='200px'>
		<!-- <div style="height: 300px;overflow: hidden;overflow-y: scroll;"> -->
			<div class="display-flex align-items-center justify-content-center" v-for="(item,index) in agencyList" :key='index' @click="industryChange(index)">
				<div class="fz16  co-333333 h40  cure-pointer hovers">
					{{item.name}}
				</div>
			</div>
		<!-- </div> -->
	</el-dialog>
	<Preview ref="Preview"></Preview>
</template>

<script>
	import Preview from "@/components/Preview.vue";
	export default {
		components: {
			Preview,	
		},
		name: 'Cooperation',
		data() {
			return {
				applyPopup: false, // 是否显示申请弹窗
				applyType: 1, // 1个人申请 2企业申请
				name: '', // 个人姓名
				schoolName: '', // 毕业院校
				overseasName: '', // 海外院校
				isHave: 0, // 是否有教育经验
				workLink: '', // 作品集链接
				mechanismname: '', // 机构名称
				mechanismtime: '', // 机构创办时间
				mechanismtypee: '', // 机构类型
				mechanismtypeeid:'',
				phone: '', // 电话
				wechat: '', // 微信号
				info: {}, //客服信息
				agencyList: [], //机构类型
				maskBtn: false,
				bigImgSrc: '',
				industry:false
			}
		},
		mounted() {
			this.$req({
				method: 'get',
				url: '/api/tags',
				data: {
					type: 'agency_type',
				},
				success: res => {
					this.agencyList = res;
					console.log(this.agencyList)
				},
				fail: error => {

				}
			});
			this.$req({
				method: 'get',
				url: '/api/config',
				data: {
					name: 'join_us_banner,join_us_qrcode,join_us_phone,join_us_wechat',
				},
				success: res => {
					this.info = res
					// console.log(this.info)
				},
				fail: error => {

				}
			});
		},
		methods: {
			
			bigImgShow(url){
				this.$refs.Preview.bigImgShow(url)
			},
			selectDate(dateData) {
				let date = new Date(dateData)
				let y = date.getFullYear()
				let m = date.getMonth() + 1
				m = m < 10 ? ('0' + m) : m
				let d = date.getDate()
				d = d < 10 ? ('0' + d) : d
				const time = y + '-' + m + '-' + d;
				this.mechanismtime = time;
				console.log(this.mechanismtime)
			},
			datePickerChange(event) {
				this.selectDate(event)
			},
			industryChange(index){
				// this.agencyList[index]
				this.mechanismtypee = this.agencyList[index].name
				this.mechanismtypeeid =this.agencyList[index].id;
				this.industry = !this.industry;
			},
			// 保存
			save() {
				let obj = {}
				var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
				if (this.applyType == 1) {
					if (!this.name) {
						this.$message.error('请输入姓名');
						return false
					}
					if (!this.schoolName) {
						this.$message.error('请输入毕业院校');
						return false
					}
					if (!this.overseasName) {
						this.$message.error('请输入海外院校');
						return false
					}
					if (!this.workLink) {
						this.$message.error('请输入作品集链接');
						return false
					}
					if (!this.workLink) {
						this.$message.error('请输入作品集链接');
						return false
					}
					obj = {
						type: 'personal',
						name: this.name,
						school: this.schoolName,
						oversea_school: this.overseasName,
						has_teaching: this.isHave,
						works: this.workLink,
						wechat_number: this.wechat,
						phone: this.phone,
					}
				} else {
					if (!this.mechanismname) {
						this.$message.error('请输入机构名称');
						return false
					}
					if (!this.mechanismtime) {
						this.$message.error('请输入机构创办时间');
						return false
					}
					if (!this.mechanismtypee) {
						this.$message.error('请输入机构类型');
						return false
					}
					obj = {
						type: 'agency',
						name: this.mechanismname,
						entrance_date: this.mechanismtime,
						agency_id: this.mechanismtypeeid,
						wechat_number: this.wechat,
						phone: this.phone,
					}
				}
				if (!this.phone) {
					this.$message.error('请输入手机号');
					return false
				}
				if (this.phone.length < 11) {
					this.$message.error('电话号不能少于11位');
					return false
				}
				if (!myreg.test(this.phone)) {
					this.$message.error('手机号格式不正确');
					return false;
				}
				if (!this.wechat) {
					this.$message.error('请输入微信号');
					return false
				}
				this.$req({
					method: 'post',
					url: '/api/join',
					data: obj,
					success: res => {
						this.$message.success('保存成功');
						this.cancel()
					},
					fail: error => {
						console.log(error)
					}
				});

			},
			// 取消
			cancel() {
				this.applyPopup = false;
			}
		}
	}
</script>

<style lang="less">
	
	.hovers:hover {
		color: #010085;
	}
	



	.cooperation_pic {
		width: 980px;
		height: 160px;
	}

	.coo_01 {
		width: 19.96px;
		height: 14.95px;
	}

	.coo_02 {
		width: 14px;
		height: 14px;
	}

	.code {
		width: 50px;
		height: 50px;
	}

	.co-name-title {
		width: 130px;
		letter-spacing: 10px;
	}

	.is-have {
		width: 20px;
		height: 20px;
	}

	.input-popup-area {
		padding: 0 16px;
		width: 284px;
		height: 32px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
	}

	.popup-name {
		width: 130px;
	}

	.input-popup {
		outline: none;
		border: 0;
		padding: 0;
		margin: 0;
	}

	.btn-popup-01 {
		width: 72px;
		height: 28px;
		background: #DCFF03;
		border-radius: 3px;
	}

	.btn-popup-02 {
		width: 70px;
		height: 26px;
		border: 1px solid #999999;
		border-radius: 3px;
	}

	.el-input__inner {
			
		    width: 320px;
		    height: 32px;
		    background: #FFFFFF;
		    border: 1px solid #CCCCCC;
	}

	.el-input__prefix {
		opacity: 0;
	
		
	}
	.el-input--prefix .el-input__inner {
	    padding-left: 15px !important;
		color: #333333;
	}

	input::-webkit-input-placeholder {
		color: #999999;
		font-size: 16px;
	}

	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
		margin: 0;
		-moz-appearance: textfield;
	}

	textarea::-webkit-input-placeholder {
		color: #999999;
		font-size: 16px;
	}
</style>
